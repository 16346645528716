:root {
	/* --primary-color: #23A4D6;
	--dark-color: #293745; */
	/* #1888f */
	--dark-color: #242222;
	--primary-color: #23a4d6;
	--secondary-color: #1888ff;
	--light-color: #f4f4f4;
	--danger-color: #dc3545;
	--success-color: #28a745;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'PT Sans', sans-serif;
}
body {
	/* font-family: 'Raleway', sans-serif; */
	font-size: 1rem;
	line-height: 1.6;
	background-color: #fcfcfc;
	color: #333;
	height: 100%;
}

a {
	color: var(--primary-color);
	text-decoration: none;
}

ul {
	list-style: none;
}

img {
	width: 100%;
}

.margin-left {
	margin-left: 15px;
}

/* Utilities */
.container {
	max-width: 1100px;
	margin: auto;
	overflow: hidden;
	padding: 0 2rem;
	margin-top: 6rem;
	margin-bottom: 3rem;
}

/* Text Styles*/
.x-large {
	font-size: 4rem;
	line-height: 1.2;
	margin-bottom: 1rem;
}

.large {
	font-size: 3rem;
	line-height: 1.2;
	margin-bottom: 1rem;
}
.lead-1 {
	font-size: 2rem;
	margin-bottom: 1rem;
}
.lead {
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

.text-center {
	text-align: center;
}

.text-primary {
	color: var(--primary-color);
}

.text-dark {
	color: var(--dark-color);
}

/* Padding */
.p {
	padding: 0.5rem;
}
.p-1 {
	padding: 1rem;
}
.p-2 {
	padding: 2rem;
}
.p-3 {
	padding: 3rem;
}
.py {
	padding: 0.5rem 0;
}
.py-1 {
	padding: 1rem 0;
}
.py-2 {
	padding: 2rem 0;
}
.py-3 {
	padding: 3rem 0;
}

/* Margin */
.m {
	margin: 0.5rem;
}
.m-1 {
	margin: 1rem;
}
.m-2 {
	margin: 2rem;
}
.m-3 {
	margin: 3rem;
}
.my {
	margin: 0.5rem 0;
}
.my-1 {
	margin: 1rem 0;
}
.my-2 {
	margin: 2rem 0;
}
.my-3 {
	margin: 3rem 0;
}

/* colour styles */
.secondary-color {
	color: var(--secondary-color);
}
.dark-color {
	background-color: var(--dark-color) !important;
}

.btn {
	display: inline-block;
	background: var(--light-color);
	color: #333;
	padding: 0.4rem 1.3rem;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	margin-right: 0.5rem;
	transition: opacity 0.2s ease-in;
	outline: none;
	border-radius: 4px;
}

.badge {
	font-size: 0.8rem;
	padding: 0.1rem;
	text-align: center;
	margin: 0.3rem;
	background: var(--light-color);
	color: #333;
}

.alert {
	padding: 0.8rem;
	margin: 1rem 0;
	opacity: 0.9;
	background: var(--light-color);
	color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
	background: var(--primary-color);
	color: #fff;
	border-radius: 4px;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
	background: var(--light-color);
	color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
	background: var(--dark-color);
	color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
	background: var(--danger-color);
	color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
	background: var(--success-color);
	color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
	background: #fff;
	color: #333;
	border: #ccc solid 1px;
}

.btn:hover {
	opacity: 0.8;
}

.bg-light,
.badge-light {
	border: #ccc solid 1px;
}

.round-img {
	border-radius: 50%;
}

.line {
	height: 1px;
	background: #ccc;
	margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.content-container {
	min-height: calc(100vh - 200px);
}

.footer-container {
	position: relative;
	left: 0;
	bottom: 0;
}

/* Forms */

.login-box {
	max-width: 330px;
	margin: auto;
}

.form .form-group {
	margin: 1.2rem 0;
}

.form .form-text {
	display: block;
	margin-top: 0rem;
	color: #888;
}
.form-btn {
	width: 100%;
	margin-top: 20px;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form i,
.form textarea {
	/* display: block; */
	/* width: 100%; */
	padding: 0.4rem;
	font-size: 1.2rem;
	/* border: 1px solid #ccc; */
}
.textbox {
	width: 100%;
	overflow: hidden;
	font-size: 20px;
	padding: 8px 0;
	margin: 8px 0;
	border-bottom: 1px solid var(--secondary-color);
}
.textbox i {
	width: 26px;
	float: left;
	text-align: center;
	color: var(--secondary-color);
}
.textbox input {
	border: none;
	outline: none;
	background: none;
	color: black;
	font-size: 18px;
	width: 80%;
	float: left;
	margin: 0 10px;
}

.heading {
	margin: 20px 0px;
	float: left;
	font-size: 40px;
	border-bottom: 6px solid var(--secondary-color);
	margin-bottom: 50px;
	padding: 13px 0;
}
.head2 {
	font-size: 30px;
	margin-bottom: 30px;
}

.form input[type='submit'],
button {
	font: inherit;
}

.form .social-input {
	display: flex;
}

.form .social-input i {
	padding: 0.5rem;
	width: 4rem;
}

.form .social-input i.fa-twitter {
	color: #38a1f3;
}
.form .social-input i.fa-facebook {
	color: #3b5998;
}
.form .social-input i.fa-instagram {
	color: #3f729b;
}
.form .social-input i.fa-youtube {
	color: #c4302b;
}
.form .social-input i.fa-linkedin {
	color: #0077b5;
}

.table th,
.table td {
	padding: 1rem;
	text-align: left;
}

.table th {
	background: var(--light-color);
}

/* Navbar */
.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.7rem 2rem;
	position: fixed;
	z-index: 1;
	width: 100%;
	top: 0;
	border-bottom: solid 1px var(--primary-color);

	/* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
	background: var(--dark-color);
	left: 0;
	opacity: 1;
	transition: all 0.5s ease;

	height: 80px;
	justify-content: center;
	font-size: 1.2rem;
}

.navbar-logo {
	color: #fff;
	justify-self: start;
	margin-left: 20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
}

.fa-code {
	margin-left: 0.5rem;
	font-size: 1.6rem;
}

.nav-link a {
	color: #fff;

	/* margin: 0 0.25rem; */
	padding: 8px 16px;
}

.nav-link a:hover {
	background-color: var(--secondary-color);
	border-radius: 4px;
	transition: all 0.2s ease-out;
	padding: 8px 16px;
}

.navbar ul {
	display: flex;
	display: grid;
	grid-template-columns: repeat(4, auto);
	grid-gap: 10px;
	list-style: none;
	text-align: center;
	width: 70vw;
	justify-content: flex-end;

	margin-right: 2rem;
}
/* .navbar li{
	display: flex;
  align-items: center;
  height: 80px;
} */

.navbar a {
}

.navbar a:hover {
	/* color: var(--primary-color); */
}

.navbar .welcome span {
	margin-right: 0.6rem;
}

/* Footer */
footer {
	/* margin-top:70px; */
	position: flex;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--dark-color);
	height: auto;
	width: 100vw;
	/* padding-top: 40px; */
	color: var(--light-color);
}
.footer-content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
}
.footer-content h3 {
	font-size: 1.8rem;
	font-weight: 400;
	text-transform: capitalize;
	line-height: 3rem;
}
.footer-content p {
	max-width: 500px;
	/* margin: 10px auto; */
	line-height: 30px;
	font-size: 16px;
	padding: 30px;
}
.socials {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem 0 3rem 0;
}
.socials li {
	margin: 0 10px;
}
.socials a {
	text-decoration: none;
	color: var(--light-color);
}
.socials a i {
	font-size: 1.1rem;
	transition: color 0.4s ease;
}
.socials a:hover i {
	color: var(--primary-color);
}

.footer-bottom {
	background: var(--dark-color);
	width: 100vw;
	padding: 20px 0;
	text-align: center;
}
.footer-bottom p {
	font-size: 14px;
	word-spacing: 2px;
	text-transform: capitalize;
}
.footer-bottom span {
	text-transform: uppercase;
	opacity: 0.4;
	font-weight: 200;
}

/* Landing Page */
.landing {
	position: relative;
	background: url('./img/showcase.jpg') no-repeat center center/cover;
	height: 100vh;
}

.landing-inner {
	color: #fff;
	height: 100%;
	width: 80%;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

/* Profiles Page */
.profile {
	display: grid;
	grid-template-columns: 2fr 4fr 2fr;
	align-items: center;
	grid-gap: 2rem;
	padding: 1rem;
	line-height: 1.8;
	margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
	display: grid;
	grid-template-areas:
		'top top'
		'about about'
		'exp edu'
		'github github';
	grid-gap: 1rem;
}

.profile-top {
	grid-area: top;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.profile-top img {
	width: 250px;
}

.profile-top .icons a {
	color: #fff;
	margin: 0 0.3rem;
}

.profile-top .icons a:hover {
	color: var(--dark-color);
}

.profile-about {
	grid-area: about;
	text-align: center;
}

.profile-about .skills {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.profile-exp {
	grid-area: exp;
}

.profile-edu {
	grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
	margin-bottom: 1rem;
}

.profile-exp > div,
.profile-edu > div {
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: #ccc 1px dotted;
}

.profile-exp > div:last-child,
.profile-edu > div:last-child {
	border: 0;
}

.profile-exp p,
.profile-edu p {
	margin: 0.5rem 0;
}

.profile-github {
	grid-area: github;
}

.profile-github .repo {
	display: flex;
}

.profile-github .repo > div:first-child {
	flex: 7;
	flex-basis: 70%;
}

.profile-github > div:last-child {
	flex: 3;
	flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
	background: var(--primary-color);
	padding: 0.5rem;
}

.post {
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-gap: 2rem;
	align-items: center;
}

.post > div:first-child {
	text-align: center;
}

.post img {
	width: 100px;
}

.post .comment-count {
	background: var(--light-color);
	color: var(--primary-color);
	padding: 0.1rem 0.2rem;
	border-radius: 5px;
	font-size: 0.8rem;
}

.post .post-date {
	color: #aaa;
	font-size: 0.8rem;
	margin-bottom: 0.5rem;
}

/* Mobile Styles */
@media (max-width: 900px) {
	.container {
		margin-top: 8rem;
	}

	.hide-sm {
		display: none;
	}

	/* Text Styles */
	.x-large {
		font-size: 3rem;
	}

	.large {
		font-size: 2rem;
	}
	.lead-1 {
		font-size: 1.2rem;
		padding-top: 0px;
		margin-top: 0px;
	}
	.lead {
		font-size: 1rem;
	}

	/* Navbar */
	.navbar {
		display: block !important;
		text-align: center !important;
		margin: 0px;
		padding: 0px;
	}

	.navbar-logo {
		background-color: var(--dark-color);
	}

	.navbar ul {
		text-align: center;
		justify-content: center;
		display: relative;
		background-color: var(--dark-color);
		padding-bottom: 1rem;
		width: 100vw;

		/* grid-template-columns: repeat(5, auto); */
		/* grid-gap: 0px; */
		/* list-style: none; */
		/* justify-content: none; */
	}

	.nav-link {
		font-size: 16px;
	}

	.navbar h1 {
		margin-bottom: 1rem;
	}

	.navbar .welcome {
		display: none;
	}
	.button-link a:hover,
	.nav-link a:hover {
		color: var(--secondary-color);
		background-color: transparent;
		border-radius: 0px;
		transition: none;
		border: transparent;	}
	/* .navbar li{
		display: flex;
		align-items: center;
		height: 80px;
	} */

	/* Profiles Page */
	.profile {
		grid-template-columns: 1fr;
		text-align: center;
	}

	.profile ul {
		display: none;
	}

	/* Profile Page */

	.profile-top img,
	.profile img {
		width: 200px;
		margin: auto;
	}

	.profile-grid {
		grid-template-areas:
			'top'
			'about'
			'exp'
			'edu'
			'github';
	}

	.profile-about .skills {
		flex-direction: column;
	}

	.dash-buttons a {
		display: block;
		width: 100%;
		margin-bottom: 0.2rem;
	}

	.post {
		grid-template-columns: 1fr;
	}
	.post a,
	.post button {
		padding: 0.3rem 0.4rem;
	}
}

:root {
    --primary: #1888ff;
  }
  
  .button-box {
    /* padding: 8px 20px; */
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    background-color: var(--primary);
    padding: 8px 20px;

  }
  
  .button-box:hover {
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: #fff;
    border-radius: 4px;
    border: 2px solid var(--primary);
    /* transition: all 0.3s ease-out; */
  }

  @media (max-width: 900px) {


    .button-box {
      text-align: center;
      justify-content: center;
      display: relative;
      background-color: var(--dark-color);
      padding-bottom: 1rem;
      width: 100vw;
      font-size: 16px;
      margin-bottom: 2px;
      padding-bottom: 20px;

    }
    
    .button-box:hover{
      color: var(--secondary-color);
      background-color: transparent;
      border-radius: 0px;
      transition: none;
      border: transparent;
    }


  }
  